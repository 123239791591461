@media print {
  body {
    margin: 0;
    color: #000;
    background-color: #fff;
  }

  .subRoot {
    visibility: hidden;
  }

  .printDocument {
    visibility: visible;
    position: fixed;
    overflow: auto;
    top: 0px;
    left: 0px;
  }

  .btn-print {
    display: none;
  }

  .main-wrapper {
    padding: 0;
  }
}
